
import {
  IonPage,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonBackButton,
  IonTitle,
  IonContent,
  IonSpinner,
  IonText,
  IonCard,
  IonCardTitle,
  IonCardHeader,
  IonCardContent,
  IonLabel,
  IonList,
  IonItem,
  onIonViewWillEnter,
} from "@ionic/vue";
import { checkmark } from "ionicons/icons";

import { computed, onMounted, ref } from "vue";
import { useRoute, useRouter } from "vue-router";

import apiPresenze from "../services/presenze";
import { openToast } from "../services/toast";

import moment from "moment";

export default {
  name: "InterventoDetail",
  components: {
    IonHeader,
    IonToolbar,
    IonButtons,
    IonBackButton,
    IonTitle,
    IonContent,
    IonPage,
    IonSpinner,
    IonText,
    IonCard,
    IonCardTitle,
    IonCardHeader,
    IonCardContent,
    IonLabel,
    IonList,
    IonItem,
  },
  setup() {
    const router = useRouter();
    const route = useRoute();
    const id = route.params.id as string; //presenza_id by route params
    const loading = ref(false);
    const presenza = ref([]);
    const userID = JSON.parse(localStorage.getItem("userInfo")).users_id;
    //let localInterventi = JSON.parse(localStorage.getItem("interventi"));
    //console.log(localInterventi);

    function riferimentoCliente(presenza) {
      if (presenza.customers_company) {
        return `${presenza.customers_company}`;
      } else {
        return `${presenza.customers_name} ${presenza.customers_last_name}`;
      }
    }

    /**
     * Return '-' if field is null
     */
    function checkField(field) {
      if (field == null || field == "") {
        return "";
      } else {
        return field;
      }
    }

    /**
     * Format date from YYYY/MM/DD HH:mm:ss to DD/MM/YYY
     */
    function dateFormat(date) {
      if (moment.isDate(new Date(date))) {
        return (
          moment(date).format("DD/MM/YYYY") +
          " - " +
          moment(date).format("HH:mm")
        );
      } else {
        return "-";
      }
    }

    /**
     * Format presenza detail
     */
    function getPresenza() {
      loading.value = true;
      apiPresenze
        .getPresenzaDetail(id)
        .then((response) => {
          presenza.value = response;
        })
        .catch((error) => {
          //error.value = true;
          openToast("Errore durante la richiesta della presenza", "danger");
        })
        .finally(() => {
          loading.value = false;
        });
    }

    onIonViewWillEnter(() => {
      getPresenza();
    });

    onMounted(() => {
      //getPresenza();
    });

    return {
      loading,
      presenza,
      riferimentoCliente,
      dateFormat,
      checkField,
    };
  },
};
